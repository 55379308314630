import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import HomeArticleRoll from '../components/articles/HomeArticleRoll'
import SectionTitle from '../components/general/SectionTitle'
import GalleryHighlight from '../components/homepage/GalleryHighlight'
import NewsHighlight from '../components/homepage/NewsHighlight'
import HomePageHero from '../components/homepage/HomePageHero'
import WhatsNew from '../components/homepage/WhatsNew'
import YoutubeHighlight from '../components/homepage/YoutubeHighlight'
import MagazineHighlight from '../components/homepage/MagazineHighlight'
import { AiOutlineDoubleLeft } from 'react-icons/ai'

export const IndexPageTemplate = ({ image }) => (
  <div className="home-page">
    <HomePageHero
      backgroundImage={
        image.childImageSharp ? image.childImageSharp.fluid.src : image
      }
    />

    <div className="container margin-top-10">
      <div className="columns is-gapless">
        <div className="column is-8">
          <div className="column">
            <a href="/blog" className="has-text-black">
              <SectionTitle>مقالات ونصوص</SectionTitle>
            </a>
            <HomeArticleRoll />
            <div className="has-text-primary has-text-left">
              <Link to="/blog">باقي الكتابات</Link>
              <AiOutlineDoubleLeft color="#3273dc" />
            </div>
            <NewsHighlight count={4} />
          </div>
        </div>
        <div className="column is-4">
          <div className="column">
            <MagazineHighlight />
            <GalleryHighlight />
            <WhatsNew />
            <YoutubeHighlight />
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
