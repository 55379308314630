import React from 'react'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
import ArticleCard from './ArticleCard'

const BlogRollTemplate = (props) => {
  const { data = {} } = props
  const { allStrapiArticle: { edges: posts = [] } = {} } = data
  const [
    { node: firstPost },
    { node: secondPost },
    { node: thirdPost },
    { node: fourthPost },
    { node: fifthPost },
    ...otherPosts
  ] = posts

  return (
    <div className="columns is-multiline">
      {[firstPost, secondPost, thirdPost, fourthPost, fifthPost].map((post) => (
        <ArticleCard
          key={post.id}
          className="column is-full"
          excerptLength={200}
          author={post.author}
          post={post}
          showFeaturedImage
        />
      ))}

      {otherPosts &&
        otherPosts.map(({ node: post }) => (
          <ArticleCard
            key={post.id}
            className="column is-half"
            author={post.author}
            post={post}
            excerptLength={100}
            titleStyle="is-size-6"
            hideExcerpt={true}
          />
        ))}
    </div>
  )
}

const BlogRoll = () => (
  <StaticQuery
    query={graphql`
      query HomeArticlesRollQuery {
        allStrapiArticle(
          limit: 9
          sort: { order: [DESC], fields: [publishDate] }
          filter: { category: { categorySlug: { ne: "أخبار" } } }
        ) {
          edges {
            node {
              excerpt
              id
              isFeatured
              publishDate
              strapiId
              slug
              title
              updated_at
              created_at
              body

              image {
                url
                formats {
                  small {
                    url
                  }
                }
              }

              author {
                id
                name
                screenName
                image {
                  url
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRollTemplate data={data} count={count} />}
  />
)

BlogRollTemplate.propTypes = {
  data: PropTypes.shape({
    allStrapiArticle: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default BlogRoll
