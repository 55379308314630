import axios from 'axios'
import { getToken } from '../utils/auth'

const axiosInstance = () => {
  return axios.create({
    baseURL: `${process.env.GATSBY_STRAPI_API_URL}`,
    timeout: 10000,
    headers: { Authorization: `Bearer ${getToken()}` },
  })
}

export const getArticles = async () => {
  return axiosInstance().get(`/articles`)
}

export const addArticle = async (article) => {
  return axiosInstance().post(`/articles`, article)
}

export const updateArticles = async (articles) => {
  return axiosInstance().put(`/articles`, articles)
}

export const getProducts = async () => {
  return axiosInstance().get('/shop/products')
}

export const createPaymentIntent = async (body) => {
  const { data } = await axiosInstance().post(
    `/shop/create-payment-intent`,
    body
  )

  if (!data || data.error) {
    throw new Error('PaymentIntent API Error')
  } else {
    return data.client_secret
  }
}

export const getPublicStripeKey = async () => {
  const {
    data: { publicKey },
  } = await axiosInstance().get(`/shop/public-key`)
  return publicKey
}

export const saveEmail = async ({ email, list }) => {
  const MAIL_LIST_HOST =
    process.env.GATSBY_MAILHOST || 'https://maillist-dev.ssc-sudan.org'

  await axios.post(`${MAIL_LIST_HOST}/mailingList`, {
    email,
    list,
  })
}

export const getFreePublication = async ({ email, name }) => {
  await axios.post(`${process.env.GATSBY_FREEPUBLICATION_ENDPOINT}`, {
    email,
    name,
  })
}

/**
 * Call the Strapi API to store the messages
 */
export const addContactMessage = async ({ name, email, message }) => {
  const FEEDBACK_MESSAGES_HOST =
    process.env.GATSBY_STRAPI_API_URL || 'https://api-dev.ssc-sudan.org'
  await axios.post(`${FEEDBACK_MESSAGES_HOST}/feedback-messages`, {
    name,
    email,
    message,
  })
}
